export let params = new URLSearchParams(document.location.search)
// Logo
//8db5c3fb611a4cfc9ca8689722124fb3
// No Logo
//d6368bcf4ea049579f4e3d294c3fde5b
// Test Logo
// e124ef5853f24db5927d122c17dcfd10



// hdflh14dt
// 08199412d2d94c10a2ca85b18f25ab55

// hdflh14dt_logo
// c00e0ef0e14d4624985e8ce536774777


export let sceneObj = {
	menuOpen: 100,
	cart:'',
	touchScreen:'',
	animation:'',
	windowWidth:'',
	activeMaterial: '',
	activeTexture: '',
	bg: params.get('bg'),
	// Are we in edit mode? yes or no
	edit: params.get('edit'),
	myFormOptions: '',
	myFormAccessories: '',
	myFormStuds: '',
	myResize: '',
	cameraSpeed: 1,
	backrest:{
		rider: 'none',
		passenger :'none',
		chrome: 'none'
	},
	// Name of scene
	activeOption: '',
	logo: '',
	heatswitch: '',
	logoKey: '',
	optionNote: '',
	stud: '',
	studOption: '',
	texturesFolder: 'https://cdn.digitaldraping.com/corbin/3d/img/', // 'https://digitaldraping.nyc3.digitaloceanspaces.com/corbin/3d/img/',
	myEnvironment: 'default',
	partGroup: params.get('part'),
	scene: '',
	stitchingAssembly: 'no',
	uid: '',
	// myMaterialsArray
	// ALL the names
	// Seat,Side,Trim,Welt,Tail,Stitching,Logo
	// Then remove the names that are not required for this scene
	// Seat,Side,Stitching,Logo
	myMaterialsArray: [],
	// myTexturesArray is used along side myMaterialsArray but holds the active texture for each
	// This is always updated with the latest selections. Alongside activeMaterial.
	// black_natural_leather,blue_metal_flake,black,red
	myTexturesArray: [],
	// myOptionsArray this is an object that holds all information for all of the options
	// name: 'K-LR-22-DL-E'
	// description: 'Dual Platform saddle w/ HEAT, 2022 K...
	myOptionsArray: [],
	orderTexturesGroupArray: [],
	myNodes: [],
	staticTextureArray: [],
	staticMaterialArray: [],
	myCameraObj:{},
	logosObj: {},
	everyTexturesAttributesObj: {},
	environmentObj: {},
	sketchfabAPI: {},
	stitchPatternAlphaArray: [],
	stitchPattern:'',
	stitchPatternColor:'',
	stitchPatternColorArray: [],
	dbParam: '',
	texturesMatrix: {},
	preferencesObj:{},
	hideObj:{},
	alphaObj:{},
	hideShowObj:{},
	consoleLog:{},
	centerModelPosition:[],
	centerModelTarget:[],
	lightSource:[],
}
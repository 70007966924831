import { sceneObj } from './global_vars.js'
import { myOptionName, myOptionDescription } from './options.js'
import { shortURL } from './url_short.js'
import { menuHeaderColor, formatTextTitle, toTitleCase, isInvalid } from './global_popular_functions.js'
import { getOptionNotes } from './option_notes.js'

export function addCart() {
	if (sceneObj.cart != 'no'){
		$('.configurator').append('<div id="cart">' +
			'<div id="cart-menu-head" class="menu-head" name="cart">' +
			'Cart' +
			'</div>' +
			'<div id="buttons-cart" class="buttons">' +
			'</div>')
	}

	// If there is a note lets add it to the menu below the cart
	getOptionNotes()
}

$(document).on('click', '#cart-menu-head', function() {
	sceneObj.menuOpen = 'cart'
	$('.buttons').empty()
	sceneObj.activeMaterial = $(this).parent().attr('id')
	getShortURL()
	// use this as a filler untill we have the URL...
	$('#buttons-cart').append('<div class="cart-getting-data">Getting Data...</div>')
	menuHeaderColor()
})

async function getShortURL() {
	await shortURL(window.location.href).then((data) => {
		sendToCart(data)
	})
}

function getTextureAdditionalPrices(){
	let textureNumOfBackrests = 0;
	let myTexturesArrayPrice = [];
	let myTexturesMaterial = [];
	let myTexturesName = [];
	let myTexturesBackrest = [];
	let myBackrest = [];

	// Do we have more than 1 backrest
	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
			if (sceneObj.myOptionsArray[i].partGroup == 'backrest'){
				textureNumOfBackrests++;
				myBackrest.push('Rider')
			}
			if (sceneObj.myOptionsArray[i].partGroup == 'backrest-passenger'){
				textureNumOfBackrests++;
				myBackrest.push('Passenger')
			}
		}
	}

	// If texture prices > 0
	for (let i = 0; i < sceneObj.myTexturesArray.length; i++) {
		if (sceneObj.everyTexturesAttributesObj[sceneObj.myTexturesArray[i]].texturePrice > 0){
			myTexturesArrayPrice.push(sceneObj.everyTexturesAttributesObj[sceneObj.myTexturesArray[i]].texturePrice)
			myTexturesMaterial.push(sceneObj.myMaterialsArray[i])
			myTexturesName.push(sceneObj.myTexturesArray[i])
			if (sceneObj.myMaterialsArray[i] == 'plate') {
				myTexturesBackrest.push(myBackrest[0])
			}else {
				myTexturesBackrest.push('')
			}
			if ((textureNumOfBackrests > 1) && (sceneObj.myMaterialsArray[i] == 'plate')) {
				myTexturesArrayPrice.push(sceneObj.everyTexturesAttributesObj[sceneObj.myTexturesArray[i]].texturePrice)
				myTexturesMaterial.push(sceneObj.myMaterialsArray[i])
				myTexturesName.push(sceneObj.myTexturesArray[i])
				myTexturesBackrest.push(myBackrest[1])
			}
		}
	}

	// [["56.00","56.00"],["plate","plate"],["chrome_plate","chrome_plate"]]
	return [myTexturesArrayPrice, myTexturesMaterial, myTexturesName, myTexturesBackrest]
}

function sendToCart(sURL) {
	let additionalTexturePrice = getTextureAdditionalPrices()
	//console.log('additionalTexturePrice',additionalTexturePrice)

	let priceArray = []
	let shippingArray = []
	let myForm = ''

	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
			let myName = sceneObj.myOptionsArray[i].name
			priceArray.push(sceneObj.myOptionsArray[i].price)
			shippingArray.push(sceneObj.myOptionsArray[i].shipping)
			myForm += '<input type="hidden" name="item-#' + myName +
				'|' + sceneObj.myOptionsArray[i].price +
				'|Corbin Part #' +
				'|n/a' +
				'|0" size="3" value="1">' // 10
		}
	}

	for (let i = 0; i < additionalTexturePrice[0].length; i++) {
		myForm += '<input type="hidden" name="item-#' +  formatTextTitle(additionalTexturePrice[2][i]) + ' ' + additionalTexturePrice[3][i] +
			'|' + additionalTexturePrice[0][i] +
			'|Corbin Part #' +
			'|n/a' +
			'|0" size="3" value="1">' // 10
	}

	// Add Logo Info

	// is null undefined NaN
	if (typeof sceneObj.logo !== 'undefined' && sceneObj.logo) {
		// It's not undefined, null or NaN
		myForm += '<input type="hidden" name="item-#LOGO|0.00|Logo Design: ' + formatTextTitle(sceneObj.logo) + '|n/a|0" size="3" value="1">'
		for (let i = 0; i < 4; i++) {
			let layer = 'layer_'+i
			let name = 'name_'+i
			if ((sceneObj.logosObj[sceneObj.logo][layer] != 'image')&&(sceneObj.logosObj[sceneObj.logo][layer] != 'none')) {
				myForm += '<input type="hidden" name="OPTION|' + (i+1) + '|#LOGO" value="' + toTitleCase(sceneObj.logosObj[sceneObj.logo][name]) + ' - ' + toTitleCase(sceneObj.logosObj[sceneObj.logo][layer]) + '|0.00|0">'
			}
		}
	}else {
		// It's undefined, null or NaN
	}

	// Add Stitch Pattern - pillion 50
	if (isInvalid(sceneObj.stitchPattern) == false) {
		myForm += '<input type="hidden" name="item-#Stitch Pattern|0.00|Stitch Pattern: ' + formatTextTitle(sceneObj.stitchPattern) + ' - '  + formatTextTitle(sceneObj.stitchPatternColor) + '|n/a|0" size="3" value="1">'
	}

	// NOTES
	// If there is a note lets add it to the form
	if (typeof sceneObj.optionNote !== 'undefined' && sceneObj.optionNote) {
		myForm += '<input type="hidden" name="item-#NOTES|0.00|' + sceneObj.optionNote + '|n/a|0" size="3" value="1">'
	}else {
	// It's undefined, null or NaN: No Note
	}
	// NOTES

	let shipping = 0
	for (let i = 0; i < shippingArray.length; i++) {
		shipping += Number(shippingArray[i])
	} // console.log('shipping', shipping)

	myForm += '<input type="hidden" name="item-#CVR-KIT|0.00|Cover Design: ' + sceneObj.scene.toUpperCase() + '|n/a|'+ Number(shipping).toFixed(2) +'" size="3" value="1">'
	for (let i = 0; i < sceneObj.myMaterialsArray.length; i++) {
		if ((sceneObj.backrest.rider == 'none') && (sceneObj.backrest.passenger == 'none') && (sceneObj.myMaterialsArray[i] == 'plate') && (sceneObj.logo != '')) {
			// Don't add plate as backrest is not selected
		}else {
			let finish = formatTextTitle(sceneObj.myTexturesArray[i])
			myForm += '<input type="hidden" name="OPTION|' + (i + 1) + '|#CVR-KIT" value="' + formatTextTitle(sceneObj.myMaterialsArray[i]) + ' - ' + finish + '|0.00|0">'
		}
		// Add Studs to Cover Design Options
		if ((i+1) == sceneObj.myMaterialsArray.length) {
			if ((typeof sceneObj.stud !== 'undefined') && (sceneObj.stud) || (sceneObj.stud != 'no')) {
				myForm += '<input type="hidden" name="OPTION|' + (i + 2) + '|#CVR-KIT" value="' + formatTextTitle(sceneObj.stud) + '|0.00|0">'
			}
		}
	}

	let price = 0
	for (let i = 0; i < priceArray.length; i++) {
		price += Number(priceArray[i])
	}

	var selectedOptions = ""
	for (let i = 0; i < sceneObj.myOptionsArray.length; i++) {
		var myName = myOptionName(sceneObj.myOptionsArray[i].name)
		var myDescription = myOptionDescription(sceneObj.myOptionsArray[i].description)
		if (sceneObj.myOptionsArray[i].selected == 'yes') {
		selectedOptions += '<div class="option-box">' +
			'<div class="option-box-title">' +
				'<label for="html">#' + myName + '</label>' +
			'</div>' +
			'<div class="option-box-info">' +
				'<p class="option-info">' + myDescription + '</p>' +
				'<p class="product-price">'+
				'<span class="dollar">$</span>' +
				Number(sceneObj.myOptionsArray[i].price) +
				'</p>' +
			'</div>' +
		'</div>'
		}
	}

	// Additional texture price?
	for (let i = 0; i < additionalTexturePrice[0].length; i++) {
		let backrest = ''
		if (additionalTexturePrice[3][i] != '') {
			backrest = myOptionDescription(' *'+additionalTexturePrice[3][i]+'*')
		}
		selectedOptions += '<div class="option-box">' +
			'<div class="option-box-title">' +
				'<label for="html">#' + toTitleCase(additionalTexturePrice[1][i]) + '</label>' +
			'</div>' +
			'<div class="option-box-info">' +
				'<p class="option-info">' + formatTextTitle(additionalTexturePrice[2][i]) + backrest + '</p>' +
				'<p class="product-price">'+
				'<span class="dollar">$</span>' +
				Number(additionalTexturePrice[0][i]) +
				'</p>' +
			'</div>' +
		'</div>'
		price += Number(additionalTexturePrice[0][i])
	}

	// Add each input line
	$('.buttons').empty()
	$('#buttons-cart').append(selectedOptions +
		'<div class="total-price">Total: <span class="dollar">$</span>' + price.toFixed(2) + '</div>' +
		'<form method="post" action="https://corbin.com/cgi-bin/quikstore.cgi?add_to_cart=yes">\n' +
		myForm +
		'<INPUT TYPE="hidden" NAME="item-#URL|0.00|' + sURL + '|n/a|0" size="3" VALUE="1">\n' +
		'<INPUT TYPE="hidden" NAME="store_type" VALUE="html">\n' +
		'<INPUT TYPE="hidden" NAME="page" VALUE="">\n' +
		'<INPUT class="cart-form-image" type="image" name="add_to_cart" value="yes" srcset="' + sceneObj.texturesFolder + '/config/cart@2x.png 2x" src="' + sceneObj.texturesFolder + 'config/cart.png">\n' +
		'</form>')
}
import { sceneObj } from './global_vars.js'

export function correctSettings() {
	environmentScene()
	correctCameraConstraints()
	correctPostProcessingSettings()
	correctLightSettings()
	correctBackground()
	correctEnvironmentSettings()
}

export function correctEnvironmentSettings() {
	// correctEnviroment()
	sceneObj.sketchfabAPI.setEnvironment({
		uid: sceneObj.environmentObj.udi, // EXAMPLE Industrial Room
		enabled: true
	}, function() {
		// console.log('Environment changed')
		setTimeout(function(){
			sceneObj.sketchfabAPI.setEnvironment({
				exposure: sceneObj.environmentObj.exposure, // Brightness
				lightIntensity: sceneObj.environmentObj.lightIntensity,
				rotation: sceneObj.environmentObj.rotation,
				blur: sceneObj.environmentObj.blur,
				shadowEnabled: true
			}, function() {
				sceneObj.sketchfabAPI.getEnvironment(function(err, envInfo) {
					if (!err) {
						// console.log('Current environment:', envInfo)
						// console.log('sceneObj.environmentObj.udi:', sceneObj.environmentObj.udi)
					}
				})
			})
		},2000)
	})
}
// function correctEnviroment() {
// 	console.log(sceneObj.myOptionsArray[0].environment)
// 	console.log(isInvalid(sceneObj.myOptionsArray[0].environment))
// 	if (isInvalid(sceneObj.myOptionsArray[0].environment) == false) {
// 		sceneObj.myEnvironment = sceneObj.myOptionsArray[0].environment
// 	}
// }

// Sets a color background
export function correctBackground() {
	sceneObj.sketchfabAPI.setBackground({transparent: true}, function() {
		if ((sceneObj.bg >= 0) && (sceneObj.bg !== null)) {
		    sceneObj.sketchfabAPI.setBackground({ color: [sceneObj.bg, sceneObj.bg, sceneObj.bg] }, function() {
		        console.log('Background changed 1', sceneObj.bg);
		    });
		}
	})
}

export function environmentScene() {
	sceneObj.sketchfabAPI.setShadingStyle(sceneObj.environmentObj.renderer, { type: sceneObj.environmentObj.shading }, function(err) {
		if (!err) {
			//console.log('Shading style set')
		}
	})
}

export function correctCameraConstraints() {
	let booZoom = false
	if (sceneObj.environmentObj.zoom == 'true') {
		booZoom = true
	}
	sceneObj.sketchfabAPI.setCameraConstraints({
		fov: parseInt(sceneObj.environmentObj.fov),
		usePanConstraints: false,
		useYawConstraints: false,
		useCameraConstraints: true,
		useZoomConstraints: booZoom,
		usePitchConstraints: true,
		zoomIn: Number(sceneObj.environmentObj.zoomIn),
		zoomOut: Number(sceneObj.environmentObj.zoomOut),
		down: Number(sceneObj.environmentObj.down),
		up: Number(sceneObj.environmentObj.up),
	}, function(err) {
		if (!err) {
			// This seems to error but with no error
			// However the constraints get set
			// console.log('Set camera constraints')
		} else {
			// console.log('Set camera constraints err',err)
		}
	})
	sceneObj.sketchfabAPI.setEnableCameraConstraints(true, { preventCameraConstraintsFocus: false }, function(err) {
		if (!err) {
			// console.log('setEnableCameraConstraints')
		} else {
			console.log('setEnableCameraConstraints err',err)
		}
	})
}

// Lets make sure that the post processing is consistent
export function correctPostProcessingSettings() {
	let booSharpen = false
	if (sceneObj.environmentObj.sharpenEnable == 'true') {
		booSharpen = true
	}
	sceneObj.sketchfabAPI.getPostProcessing(function(settings) {
		//console.log(settings)
	})
	sceneObj.sketchfabAPI.setPostProcessing({
		"postProcessReady": true,
		"rgbmDefault": 7,
		"rgbmBloom": 1,
		"sssCurrentProfile": 0,
		"webVR": false,
		"AR": false,
		"veilFactor": 1,
		"doDistortionVR": false,
		"distortion": {
			"0": 0.441,
			"1": 0.156
		},
		"projectionLeft": {
			"0": 1,
			"1": 1,
			"2": -0.5,
			"3": -0.5
		},
		"unprojectionLeft": {
			"0": 1,
			"1": 1,
			"2": -0.5,
			"3": -0.5
		},
		"vrFadeLength": 0.1,
		"vrStartFade": false,
		"vrFadeStartTime": 0,
		"vrFading": false,
		"enable": true,
		"grainEnable": false,
		"grainAnimated": false,
		"grainFactor": 0.15,
		"sharpenEnable": booSharpen,
		"sharpenFactor": sceneObj.environmentObj.sharpenFactor,
		"chromaticAberrationEnable": false,
		"chromaticAberrationFactor": 0.05,
		"vignetteEnable": false,
		"vignetteAmount": 0.475,
		"vignetteHardness": 0.725,
		"bloomEnable": false,
		"bloomFactor": 0.1,
		"bloomThreshold": 0.2,
		"bloomRadius": 0.3,
		"toneMappingEnable": false,
		"toneMappingMethod": "default",
		"toneMappingExposure": 1,
		"toneMappingBrightness": 0,
		"toneMappingContrast": 0,
		"toneMappingSaturation": 1,
		"colorBalanceEnable": false,
		"colorBalanceLow": {
			"0": 0,
			"1": 0,
			"2": 0
		},
		"colorBalanceMid": {
			"0": 0,
			"1": 0,
			"2": 0
		},
		"colorBalanceHigh": {
			"0": 0,
			"1": 0,
			"2": 0
		},
		"dofEnable": false,
		"dofBlurNear": 0,
		"dofBlurFar": 0.4,
		"ssrEnable": false,
		"ssrFactor": 1,
		"ssaoEnable": true,
		"ssaoRadius": 0.00941616198014059,
		"ssaoIntensity": 0.2,
		"ssaoBias": 0.0018832323960281177,
		"taaEnable": true,
		"taaTransparent": true,
		"sssProfiles": [{
				"falloff": [
					1,
					0.37,
					0.3
				],
				"strength": [
					0.48,
					0.41,
					0.28
				]
			},
			{
				"falloff": [
					0.5,
					0.67,
					0.6
				],
				"strength": [
					0.38,
					0.61,
					0.68
				]
			},
			{
				"falloff": [
					0.7,
					0.77,
					0.3
				],
				"strength": [
					0.88,
					0.61,
					0.28
				]
			}
		],
		"sssKernelDone": true
	}, function() {
		// window.console.log('Post-processing filters set')
		// sceneObj.sketchfabAPI.getPostProcessing(function(settings) {
		//     window.console.log(settings)
		// })
	})
}

// CORBIN
// Lets make sure that the lighting is consistent
function correctLightSettings() {

	sceneObj.sketchfabAPI.setLightFeatureEnabled(true, function(err) {
		if (!err) {
			// window.console.log("Lights ready to be used");
		}
	});



	sceneObj.sketchfabAPI.setLight(0,
	sceneObj.lightSource[0],
	function(err) {
		if (!err) {
			//console.log('Set light 0',sceneObj.lightSource[0])
		}
	})

	sceneObj.sketchfabAPI.setLight(1,
	sceneObj.lightSource[1],
	function(err) {
		if (!err) {
			//console.log('Set light 1',sceneObj.lightSource[1])
		}
	})

	sceneObj.sketchfabAPI.setLight(2,
	sceneObj.lightSource[2],
	function(err) {
		if (!err) {
			//console.log('Set light 2',sceneObj.lightSource[2])
		}
	})

	// sceneObj.sketchfabAPI.getLight(0, function(err, state) {
	// 	window.console.log('0',state);
	// });
	// sceneObj.sketchfabAPI.getLight(1, function(err, state) {
	// 	window.console.log('1',state);
	// });
	// sceneObj.sketchfabAPI.getLight(2, function(err, state) {
	// 	window.console.log('2',state);
	// });

}